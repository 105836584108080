import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import '../stylesheets/top.scss';

window.addEventListener("load", () => {
  /*===========================================================*/
  /* Top Page Carousel */
  /*===========================================================*/

  const climateChangeOptions = {
    slidesPerView: "auto",
    spaceBetween: 20,
    initialSlide: 2,
    loop: false,
    centeredSlides: true,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  };

  new Swiper(".swiper.swiper-climate-change", climateChangeOptions);

});
